import React, { FC, useCallback } from 'react';
import { Link } from 'gatsby';

import { useShopifyState } from '../../state/shopify-state-context';

const Cart: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { checkout, lineItems, updateItem } = useShopifyState();

  const handleVariant = (title: string) => {
    switch (title) {
      case 'Small':
        return '(s)';
      case 'Medium':
        return '(m)';
      case 'Large':
        return '(l)';
      case 'X-Large':
        return '(xl)';
      default:
        return '';
    }
  };

  const onUpdateItem = useCallback(
    (e, id, quantity) => {
      e.preventDefault();
      updateItem(id, quantity);
    },
    [updateItem]
  );

  return (
    <div className={`cart abs z8 bcw ${isOpen ? 'open' : ''}`}>
      <div>
        {checkout && lineItems.length > 0 ? (
          <div className="f fc">
            <div className="f1 p1 cart__items">
              {lineItems.map((item) => {
                return (
                  <div key={item.id} className="cart__item">
                    <div className="f jcs aic">
                      <img
                        className="x p05 mr1"
                        src={item.variant.image.src}
                        alt=""
                      />
                      <div className="normal x">
                        <div className="f aic jcb x">
                          <div>
                            {item.title}{' '}
                            <span className="xxsmall">
                              {handleVariant(item.title)}
                            </span>
                            <div className="cart__qty al">
                              <span
                                className="h4 p05 clickable"
                                onClick={(e) =>
                                  onUpdateItem(e, item.id, item.quantity - 1)
                                }
                              >
                                -
                              </span>
                              {item.quantity}
                              <span
                                className="h4 p05 clickable"
                                onClick={(e) =>
                                  onUpdateItem(e, item.id, item.quantity + 1)
                                }
                              >
                                +
                              </span>
                            </div>
                          </div>
                          <div className="xsmall">
                            $
                            {item.quantity *
                              parseInt((item.variant.price as any).amount, 10)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="cart__subtotal x ac p1">
              <h5 className="caps b m0 small p0">
                Subtotal: $
                {parseFloat((checkout.subtotalPrice as any).amount).toFixed(2)}
              </h5>
            </div>
            <div className="cart__checkout p0 m0 ac">
              <a
                // @ts-ignore
                href={checkout.webUrl}
                className="cw bcb x m0 p15 caps b block small no-under"
              >
                Checkout
              </a>
            </div>
          </div>
        ) : (
          <div className="p1 normal ac">
            <div className="p1">
              You should buy something
              <br />
              <Link to="/store" className="mt1 block b caps">
                Peep the Store
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Cart);
