import { createContext, useContext } from 'react';
import { Launch } from 'superclient';

interface SiteStateContextValue {
  nextLaunch?: Launch;
}

const initialState: SiteStateContextValue = {
  nextLaunch: undefined
};

const SiteStateContext = createContext<SiteStateContextValue>(initialState);
const useSiteState = (): SiteStateContextValue => useContext(SiteStateContext);
const {
  Provider: SiteStateProvider,
  Consumer: SiteStateConsumer
} = SiteStateContext;

export { SiteStateProvider, useSiteState, SiteStateContext, SiteStateConsumer };
