import React, { FC } from 'react';
import {
  TransitionGroup,
  Transition as ReactTransition
} from 'react-transition-group';

const timeout = 250;
const getTransitionStyles = {
  entering: {
    opacity: 0
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0
  }
};

const Transition: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <TransitionGroup className="f1 static x">
      <ReactTransition
        key={Math.random()}
        timeout={{
          enter: timeout,
          exit: timeout
        }}
        style={{ flex: 1 }}
      >
        {(status) => (
          <div
            style={{
              ...getTransitionStyles[status]
            }}
            className="x y"
          >
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};

export default React.memo(Transition);
