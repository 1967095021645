import React, { FC } from 'react';
import { Link } from 'gatsby';
import { Launch, useCountdownToLaunch } from 'superclient';

const NextLaunchBanner: FC<{ launch: Launch }> = ({ launch }) => {
  const countdown = useCountdownToLaunch(launch);

  return (
    <div className="bcy">
      <div className="container--xl ma">
        <div className="launch__next f px15 jcs aic rel">
          {launch && (
            <Link
              to={`/launches/${launch.slug.current}`}
              className="abs z1 left top x y"
            />
          )}
          <h6 className="bau caps m0 p0 f small akkura rel launch__next-title aic">
            Next Launch:
          </h6>
          {launch?.launchInfo.overrideLaunchTime ? (
            <span className="sans h4 m0 ml1 p0 caps">
              {launch.launchInfo.overrideLaunchTime}
            </span>
          ) : countdown ? (
            <div className="bau-bold h4 m0 ml05 p0 launch__next-timer js-launch-timer">
              <span className="py05 px025">{countdown.dayString}</span>
              <span className="xsmall mr1">D</span>:
              <span className="py05 px025">{countdown.hourString}</span>
              <span className="xsmall mr1">H</span>:
              <span className="py05 px025">{countdown.minuteString}</span>
              <span className="xsmall mr1">M</span>:
              <span className="py05 px025">{countdown.secondString}</span>
              <span className="xsmall">S</span>
            </div>
          ) : (
            <span className="sans h4 m0 ml1 p0">Calculating...</span>
          )}
          {launch && (
            <span className="ml1 pl1 rel akkura launch__next-mini js-mini caps small">
              {launch.launchInfo.launchMiniDescription}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default NextLaunchBanner;
