import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import ReactList from 'react-list';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Astronaut, Launch, Mission } from 'superclient';
import { useWindowSize } from '../../state/use-window-size';
import AstronautCard from '../astronauts/astronaut-card';
import LaunchCard from './search-result-launch';
import ArticleCard from './search-result-article';
import LoadingCell from './search-loading-cell';
import MissionCard from '../missions/mission-card';
import { ListTypes } from '../core/super-card';

import ClearCircle from '../svgs/clearCircle.js';
import SearchIcon from '../svgs/search-icon.js';
import { Superclient } from '../../clients/superclient';

const Search: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose
}) => {
  const [searchText, setSearchText] = useState('');
  const { isLoading, results, total, getNextPage } = Superclient.useSearch(
    searchText
  );
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (isOpen) {
      document.getElementById('universal_search_field').focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const scrollView = document.getElementById('search-scroll-view');
    if (scrollView) {
      const contentWidth = scrollView.children[0].scrollWidth;
      scrollView.scrollLeft = scrollPercentage * contentWidth;
    }
  }, [scrollPercentage]);

  const isMobile = useMemo(() => windowSize.width <= 900, [windowSize]);

  const getSize = useCallback(() => {
    return {
      width: 350,
      height: 245
    };
  }, []);

  const renderItem = useCallback(
    (index, key) => {
      const listType = isMobile ? ListTypes.CardListY : ListTypes.CardListX;

      if (!results || index >= results.length) {
        return <LoadingCell key={key} index={index} listType={listType} />;
      }

      const item = results[index];
      if (!item) {
        return <div key={key} />;
      }

      const onResultClick = () => {
        setTimeout(onClose, 500);
      };

      if (item._type === 'astronaut') {
        return (
          <AstronautCard
            key={key}
            astronaut={item._source as Astronaut}
            paramString={''}
            listType={listType}
            onClick={onResultClick}
          />
        );
      } else if (item._type === 'launch') {
        return (
          <LaunchCard
            key={key}
            launch={item._source as Launch}
            listType={listType}
            onClick={onResultClick}
          />
        );
      } else if (item._type === 'mission') {
        return (
          <MissionCard
            key={key}
            mission={item._source as Mission}
            listType={listType}
            onClick={onResultClick}
          />
        );
      } else if (item._type === 'article') {
        return (
          <ArticleCard
            key={key}
            result={item}
            listType={listType}
            onClick={onResultClick}
          />
        );
      }
      return <div>{item._type}</div>;
    },
    [isMobile, results]
  );

  return (
    <div className="search">
      <div className="f fr aic x">
        <div className="rel f fr border py025 f1">
          <div className="mx1 f aic jcc">
            <SearchIcon />
          </div>
          <input
            id="universal_search_field"
            className="search__field f1 cb mr05 small akkura caps"
            type="text"
            placeholder="SEARCH FOR ARTICLES, LAUNCHES, OR ASTRONAUTS"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div
            className={classNames('clickable mx075 f aic jcc')}
            style={{ opacity: searchText.length === 0 ? 0 : 1 }}
            onClick={() => setSearchText('')}
          >
            <ClearCircle />
          </div>
        </div>
        <div className="small caps clickable ml1 z2" onClick={onClose}>
          CANCEL
        </div>
      </div>

      {isMobile ? (
        <div
          id="search-scroll-view"
          className="search__mobile_scrollview rel"
          onScroll={() => {
            if (total === 0) {
              return;
            }
            const scrollView = document.getElementById('search-scroll-view');

            const percentage =
              scrollView.scrollTop /
              (scrollView.scrollHeight - scrollView.offsetHeight);

            setScrollPercentage(percentage);

            const nextPageThreshold =
              Math.max((results ? results.length : 0) - 20, 0) / total;

            if (percentage > nextPageThreshold) {
              getNextPage();
            }
          }}
        >
          <ReactList
            itemRenderer={renderItem}
            length={total}
            type="uniform"
            axis="y"
            minSize={10}
          />
          {!isLoading && (!results || results.length === 0) && (
            <div className="abs x y top left f aic">
              <div className="h1 x tw">
                {searchText.length === 0
                  ? 'The truth is out there...'
                  : 'No results.'}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <ScrollContainer
            className="search__desktop_scrollview rel"
            hideScrollbars
            horizontal
            onScroll={(e) => {
              if (total === 0) {
                return;
              }
              const scrollView = document.getElementsByClassName(
                'search__desktop_scrollview'
              )[0];

              const percentage =
                scrollView.scrollLeft /
                (scrollView.scrollWidth - scrollView.clientWidth);

              setScrollPercentage(percentage);

              const nextPageThreshold = Math.max(results.length - 20, 0) / total;

              if (percentage > nextPageThreshold) {
                getNextPage();
              }
            }}
          >
            <ReactList
              itemRenderer={renderItem}
              length={Math.min(total, 100)}
              itemSizeGetter={getSize}
              type="simple"
              useStaticSize={true}
              axis="x"
            />
            {!isLoading && (!results || results.length === 0) && (
              <div className="abs x y top left f aic">
                <div className="h1 x tw">
                  {searchText.length === 0
                    ? 'The truth is out there...'
                    : 'No results.'}
                </div>
              </div>
            )}
          </ScrollContainer>

          <div className="show--m mb1">
            {results && results.length > 0 ? (
              <div className="search__scrollbar">
                <div
                  className="search__scrollbar_handle"
                  style={{ width: '10%', left: `${scrollPercentage * 90}%` }}
                />
              </div>
            ) : (
              <div className="search__scrollbar" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Search);
