import React, { FC, useEffect, useCallback, useRef, useMemo } from 'react';
import Helmet from 'react-helmet';
import { SiteStateProvider } from '../../state/site-state-context';
import { ShopifyStateProvider } from '../../state/shopify-state-context';
import { ShopContextProvider } from '../../state/shop-context';
import Doormat from '../core/doormat';
import Header from '../header/header';
import Footer from '../footer/footer';
import Transition from './transition';
import '../../styles/main.css';
import { Superclient } from '../../clients/superclient';

const Layout: FC<{
  location: {
    hash: string;
    host: string;
    hostname: string;
    href: string;
    key: string;
    origin: string;
    pathname: string;
    port: string;
    protocol: string;
    search: string;
  };
  pageContext: {
    isFullscreen?: boolean;
    isAppEmbed?: boolean;
    overridesSubheader?: boolean;
    backgroundColor?: string;
  };
}> = ({
  children,
  location,
  pageContext: {
    isFullscreen,
    isAppEmbed,
    overridesSubheader = false,
    backgroundColor
  }
}) => {
  const nextLaunch = Superclient.useNextLaunch();

  const rafRef = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    history.pushState = ((f) =>
      function pushState() {
        const ret = f.apply(this, arguments);
        window.dispatchEvent(new Event('pushstate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
      })(history.pushState);

    history.replaceState = ((f) =>
      function replaceState() {
        const ret = f.apply(this, arguments);
        window.dispatchEvent(new Event('replacestate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
      })(history.replaceState);

    window.addEventListener('popstate', () => {
      window.dispatchEvent(new Event('locationchange'));
    });

    console.groupCollapsed('Site Credits 🚀');
    console.log('Design by GrandArmy http://grandarmy.com/');
    console.log('Developed by Harry Isaac https://harryisaac.com');
    console.groupEnd();
  }, []);

  useEffect(() => {
    rafRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(rafRef.current);
  }, []);

  const update = useCallback(() => {
    if (containerRef.current) {
      let headerHeight = 0;
      const headerElements = document.querySelectorAll('.header_element');

      headerElements.forEach((element) => {
        if ((element as HTMLElement).offsetParent !== null) {
          headerHeight += element.clientHeight;
        }
      });
      const contentEl = containerRef.current.children[0];
      if (contentEl) {
        (contentEl as HTMLDivElement).style.paddingTop = `${headerHeight}px`;
      }
    }

    rafRef.current = requestAnimationFrame(update);
  }, []);

  const containerStyle = useMemo(
    () => ({ backgroundColor: backgroundColor || 'white' }),
    [backgroundColor]
  );

  const providerValue = { nextLaunch };

  return (
    <ShopifyStateProvider>
      <SiteStateProvider value={providerValue}>
        <ShopContextProvider>
          <div style={containerStyle}>
            <Helmet
              title="Supercluster"
              meta={[
                { name: 'description', content: 'Supercluster' },
                {
                  name: 'keywords',
                  content:
                    'supercluster, space, travel, astronauts, storytelling, database, iss, nasa'
                },
                {
                  name: 'viewport',
                  content:
                    'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0'
                }
              ]}
            >
              <html lang="en" />
            </Helmet>
            {!isAppEmbed && (
              <Header
                pathname={location.pathname}
                nextLaunch={nextLaunch}
                showSubheader={!overridesSubheader}
              />
            )}
            <div
              ref={containerRef}
              className="site__container"
              data-fullscreen={isFullscreen}
              data-app-embed={isAppEmbed}
            >
              <Transition>{children}</Transition>
            </div>
            {!isFullscreen && !isAppEmbed && (
              <>
                <Footer />
                <Doormat />
              </>
            )}
          </div>
        </ShopContextProvider>
      </SiteStateProvider>
    </ShopifyStateProvider>
  );
};

export default Layout;
