import React, { FC } from 'react';

const MouseOver: FC<{
  text: string;
  textColor?: string;
  backgroundColor?: string;
  className?: string;
  style?: any;
}> = ({
  text = '',
  textColor = 'black',
  backgroundColor = 'white',
  className = '',
  style = {},
  children
}) => (
  <div className={'mouseover ' + className} style={style}>
    <div className="rel f fc aic">
      <div className={'mouseover__chevron abs'} style={{ backgroundColor }} />
      <div
        className={'mouseover__contents rel py05 px075 bau caps small ac'}
        style={{
          color: textColor,
          backgroundColor,
          width: 'max-content'
        }}
      >
        {text}
        {children}
      </div>
    </div>
  </div>
);

export default MouseOver;
