import React, { FC } from 'react';
import { LazyImage } from 'react-lazy-images';

const ImageBackground: FC<{
  source?: string;
  fadeIn?: boolean;
  alt?: string;
  lazyLoad?: boolean;
  className?: string;
  size?: string;
  position?: string;
}> = ({
  source,
  fadeIn = true,
  alt,
  lazyLoad = true,
  className,
  size = 'cover',
  position = 'center'
}) => {
  return lazyLoad ? (
    <LazyImage
      src={source || ''}
      alt={alt}
      placeholder={({ ref }) => (
        <div ref={ref} className="sanity_background_image__loader bcg" />
      )}
      actual={({ imageProps }) => (
        <div
          className={`abs fill ${fadeIn ? 'fadeIn' : ''} ` + className}
          style={{
            backgroundImage: `url(${imageProps.src})`,
            backgroundSize: size,
            backgroundPosition: position
          }}
        />
      )}
    />
  ) : (
    <div
      className={`abs fill ` + className}
      style={{
        backgroundImage: `url(${source})`,
        backgroundSize: size,
        backgroundPosition: position
      }}
      aria-label={alt}
    />
  );
};

export default React.memo(ImageBackground);
