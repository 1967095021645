import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

export enum ListTypes {
  CardGrid = 'card-grid',
  CardListX = 'card-list-x',
  CardListY = 'card-list-y'
}

export interface SuperCardProps {
  linkTo?: string;
  listType?: ListTypes;
  className?: string;
  style?: any;
  onClick?: () => void;
}

const SuperCard: FC<SuperCardProps> = ({
  linkTo,
  listType = ListTypes.CardGrid,
  className = '',
  children,
  style = {},
  onClick = () => {}
}) => {
  const content = (
    <div
      className={classNames('', {
        super_card__content_grid: listType === ListTypes.CardGrid,
        super_card__content_list_x: listType === ListTypes.CardListX,
        super_card__content_list_y: listType === ListTypes.CardListY
      })}
    >
      <div className={`super_card__content_wrapper ${className}`} style={style}>
        {children}
      </div>
    </div>
  );

  return linkTo ? (
    <Link
      to={linkTo}
      className={classNames('cb', {
        super_card__link_grid: listType === ListTypes.CardGrid,
        super_card__link_list_x: listType === ListTypes.CardListX,
        super_card__link_list_y: listType === ListTypes.CardListY
      })}
      onClick={onClick}
    >
      {content}
    </Link>
  ) : (
    <div
      className={classNames('cb', {
        super_card__link_grid: listType === ListTypes.CardGrid,
        super_card__link_list_x: listType === ListTypes.CardListX,
        super_card__link_list_y: listType === ListTypes.CardListY
      })}
    >
      {content}
    </div>
  );
};

export default SuperCard;
