import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { HeaderPage, ShopProducts } from './header';
import { useShopContext } from '../../state/shop-context';
import { ShopifyProductType } from 'superclient';

const HeaderSubnav: FC<{
  fullscreen: boolean;
  currentPage: HeaderPage;
  page: HeaderPage;
  pageIndex: number;
  isExpanded: boolean;
  isOpen: boolean;
  shopDropdown: boolean;
  shopCategories: ShopProducts[];
  shopSubNav: boolean;
  onClose: () => void;
  shopNavHelper: (showSubNav) => void;
  showShopDropdown: () => void;
  closeShopDropdown: () => void;
}> = ({
  fullscreen,
  currentPage,
  page,
  pageIndex,
  isExpanded,
  isOpen,
  shopDropdown,
  shopCategories,
  shopSubNav,
  onClose,
  shopNavHelper,
  showShopDropdown,
  closeShopDropdown
}) => {
  const { setProductTypeFilter } = useShopContext();

  // Fullscreen subnav for Shop
  if (fullscreen)
    return (
      <li
        style={{ transitionDelay: `${(pageIndex + 1) * 0.05}s` }}
        key={page.title}
        className={'px15 py075'}
      >
        <a
          className={classNames('header__hamburger__link h2 asfs', {
            header__hamburger__link_active: currentPage?.path === page.path
          })}
          onClick={shopNavHelper}
          aria-hidden={!isOpen}
        >
          {page.title}
        </a>
        <div
          className={
            'fullScreenShopOptions ' + (shopSubNav ? 'shopSubNavActive' : '')
          }
        >
          <div
            className="pt15 py075"
            onClick={() => setProductTypeFilter(undefined)}
          >
            <Link
              className={classNames('header__hamburger__link h2 asfs')}
              onClick={onClose}
              to={page.path}
              aria-hidden={!isOpen}
            >
              Products
            </Link>
          </div>
          <div
            className="pt075"
            onClick={() => {
              setProductTypeFilter(ShopifyProductType.Prints);
            }}
          >
            <Link
              className={classNames('header__hamburger__link h2 asfs')}
              onClick={onClose}
              to={page.path}
              aria-hidden={!isOpen}
            >
              Photo Prints
            </Link>
          </div>
        </div>
      </li>
    );

  // Top header subnav for Shop
  return (
    <div
      key={'shop'}
      className={'shopNavDiv ' + (shopDropdown ? 'shopDropdown f aic' : '')}
      onMouseEnter={showShopDropdown}
      onMouseLeave={closeShopDropdown}
    >
      <li
        key={page.title}
        className={classNames('caps s0 header__site-nav_link_shop', {
          active: currentPage?.path === page.path
        })}
        style={{
          borderBottom: currentPage?.path === page.path ? '2px solid black' : ''
        }}
      >
        <div
          className={classNames(
            { shopPadding: shopDropdown },
            {
              active: currentPage?.path === page.path
            }
          )}
        ></div>
        <Link
          to={page.path}
          aria-hidden={isExpanded}
          style={{ textDecoration: 'none' }}
          onClick={() => {
            setProductTypeFilter(undefined);
          }}
        >
          {page.title}
        </Link>
        <div
          className={classNames('shopOptions abs f fc jcc', {
            active: currentPage?.path === page.path,
            selecting: shopDropdown
          })}
        >
          {shopDropdown &&
            shopCategories.map((category) => {
              return (
                <div key={category.productType} className="individualOptions">
                  <div onClick={() => setProductTypeFilter(category.productType)}>
                    <Link to={page.path} aria-hidden={isExpanded}>
                      {category.title}
                    </Link>
                  </div>
                </div>
              );
            })}
        </div>
      </li>
    </div>
  );
};

export default React.memo(HeaderSubnav);
