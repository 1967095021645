import React from 'react';

export default function LongLogo({ className }) {
  return (
    <svg viewBox="0 0 1103.3 76.9" fill="currentColor" className={className}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M57.5,28.8l-23.7-2c-6.6-.9-9.1-2.1-9.1-4.5,0-3.6,5.6-4.9,18.1-4.9,8,0,17.9.7,17.9,6H85.3C85.3,7,72.7,0,44,0,10.7,0,1.3,9.3,1.3,24.2c0,11.7,7.8,18.6,23.6,20.4L50,46.5c9.8.6,13.1,2.4,13.1,5.6,0,4.7-8.3,7.2-19.5,7.2-12.4,0-18.9-3-18.9-10H0C0,74.1,22.8,76.9,43.7,76.9c24.9,0,43.7-5.6,43.7-25.5C87.3,37.2,78.4,31.5,57.5,28.8Z" />
          <path d="M221.6,59.7h20c25.5,0,39.3-9.9,39.3-28.4S267.1,2.9,241.6,2.9H194.9V73.8h26.7V59.7Zm-.4-37h19.3c8.9,0,14.5,3.3,14.5,8.6,0,5.1-5.5,8.6-14.5,8.6H221.2V22.7Z" />
          <path d="M462.7,28.7c0-15.1-10-25.8-37.8-25.8H377.5V73.8h26.7V53.5h16.2L434,73.8h28.7L447.4,50.9C456.8,48.5,462.7,39,462.7,28.7ZM425.9,36H403.8V21.6h22.1c8.8,0,11.6,3,11.6,7.2S434.7,36,425.9,36Z" />
          <path d="M157.9,39.5c0,10.5-4.4,16.7-17.5,16.7-13.4,0-17.5-6.2-17.5-16.7V2.9H96.1V42.6c0,28,19.9,34.1,44.2,34.1s44.2-6.6,44.2-34.1V2.9H157.8C157.9,2.9,157.9,34.1,157.9,39.5Z" />
          <polygon points="289.1 73.8 367.5 73.8 367.5 55 315.7 55 315.7 47.3 347.9 47.3 347.9 29.4 315.7 29.4 315.7 21.7 367.5 21.7 367.5 2.9 289.1 2.9 289.1 73.8" />
          <polygon points="601.6 2.9 575 2.9 575 73.8 645.6 73.8 645.6 54 601.6 54 601.6 2.9" />
          <path d="M712.6,39.5c0,10.5-4.4,16.7-17.5,16.7-13.4,0-17.5-6.2-17.5-16.7V2.9H650.9V42.6c0,28,19.9,34.1,44.2,34.1s44.2-6.6,44.2-34.1V2.9H712.6Z" />
          <path d="M518.9,20.6c6.5,0,15.1,1.8,17.8,8.7h29.5C561.8,4.8,541.9.1,518.7.1c-21.5,0-48.2,6.5-48.2,38.3,0,29.7,23.4,38.3,47.2,38.3,23.2,0,44.1-4.8,48.5-29.2H536.7c-2.4,8.3-11.3,8.7-17.8,8.7-8.4,0-22.1-2.3-22.1-17.8S510.6,20.6,518.9,20.6Z" />
          <path d="M1103.2,28.6c-.1-15.1-10-25.8-37.8-25.8H1018V73.7h26.7V53.3h16.2l13.6,20.3h28.8L1088,50.7C1097.3,48.4,1103.2,38.9,1103.2,28.6Zm-36.8,7.3h-22.1V21.5h22.1c8.8,0,11.6,3,11.6,7.2S1075.2,35.9,1066.4,35.9Z" />
          <polygon points="929.6 73.7 1007.9 73.7 1007.9 54.9 956.2 54.9 956.2 47.1 988.3 47.1 988.3 29.3 956.2 29.3 956.2 21.6 1007.9 21.6 1007.9 2.8 929.6 2.8 929.6 73.7" />
          <polygon points="841.2 23 867.7 23 867.7 73.7 894.4 73.7 894.4 23 919.6 23 919.6 2.8 841.2 2.8 841.2 23" />
          <path d="M804.5,28.8l-23.7-2c-6.6-.9-9.1-2.1-9.1-4.5,0-3.6,5.6-4.9,18.1-4.9,8,0,17.9.7,17.9,6h24.6C832.3,7,819.8,0,791,0c-33.3,0-42.6,9.3-42.6,24.2,0,11.7,7.8,18.6,23.6,20.4l25.1,1.9c9.8.6,13.1,2.4,13.1,5.6,0,4.7-8.3,7.2-19.5,7.2-12.4,0-18.9-3-18.9-10H747.1c0,24.8,22.8,27.6,43.7,27.6,24.9,0,43.7-5.6,43.7-25.5C834.3,37.2,825.4,31.5,804.5,28.8Z" />
        </g>
      </g>
    </svg>
  );
}
