import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { Launch, Shop, ShopifyProductType } from 'superclient';
import { useShopifyState } from '../../state/shopify-state-context';
import { useShopContext } from '../../state/shop-context';
import HeaderLaunchBanner from './header-launch-banner';
import HeaderFullscreen from './header-fullscreen';
import Cart from './header-cart';
import Search from '../search/search';
import LogoLong from '../svgs/longLogo.js';
import IGIcon from '../svgs/ig-icon.js';
import SearchIcon from '../svgs/search-icon.js';
import TwitterIcon from '../svgs/twitter-icon.js';
import CartIcon from '../svgs/cart-icon.js';
import { Superclient } from '../../clients/superclient';
import HeaderSubnav from './header-subnav';

export interface HeaderPage {
  title: string;
  path: string;
  alternatePaths: string[];
}

const pages: HeaderPage[] = [
  {
    title: 'Stories',
    path: '/',
    alternatePaths: ['/editorial']
  },
  {
    title: 'Launches',
    path: '/launches',
    alternatePaths: []
  },
  {
    title: 'Astronauts',
    path: '/astronauts',
    alternatePaths: []
  },
  {
    title: 'Stations',
    path: '/stations',
    alternatePaths: ['/iss-traffic', '/iss']
  },
  {
    title: 'Shop',
    path: '/store',
    alternatePaths: ['/products']
  },
  {
    title: 'Support',
    path: '/support',
    alternatePaths: []
  },
  // Removed for now as the podcast is on hiatus
  // {
  //   title: 'Podcast',
  //   path: 'https://podcasters.spotify.com/pod/show/supercluster',
  //   alternatePaths: []
  // },
  {
    title: 'About',
    path: '/about',
    alternatePaths: []
  }
];

export interface ShopProducts {
  title: string;
  productType: ShopifyProductType;
}

const shopCategories: ShopProducts[] = [
  {
    title: 'Products',
    productType: undefined
  },
  {
    title: 'Photo Prints',
    productType: ShopifyProductType.Prints
  }
];

const Header: FC<{
  pathname: string;
  nextLaunch?: Launch;
  showSubheader: boolean;
}> = ({ pathname, nextLaunch, showSubheader }) => {
  const { cartQuantity } = useShopifyState();

  const [shop, setShop] = useState<Shop>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [shopDropdown, setShopDropdown] = useState(false);
  const [shopSubNav, setShopSubNav] = useState(false);
  const { setProductTypeFilter } = useShopContext();

  useEffect(() => {
    Superclient.getShopIndexConfig().then((_shop) => setShop(_shop));
  }, []);

  useEffect(() => {
    hideSearch();
  }, [pathname]);

  const currentPage = useMemo(() => {
    const _pages = pages.filter((p) => {
      const hasAlternateMatch = p.alternatePaths.some((ap) =>
        pathname.startsWith(ap)
      );

      return p.path === '/'
        ? pathname === p.path || hasAlternateMatch
        : pathname.startsWith(p.path) || hasAlternateMatch;
    });
    return _pages.length > 0 ? _pages[0] : undefined;
  }, [pathname]);

  const isStore = useMemo(() => {
    return /store/.test(pathname) || /products/.test(pathname);
  }, [pathname]);

  const onCollapse = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const showSearch = useCallback(() => {
    setIsSearching(true);
    setIsExpanded(false);
  }, []);

  const hideSearch = useCallback(() => {
    setIsSearching(false);
  }, []);

  const shopNavHelper = (showSubNav) => {
    setShopSubNav(!shopSubNav);
  };

  const showShopDropdown = useCallback(() => {
    setShopDropdown(!shopDropdown);
  }, [shopDropdown]);

  const closeShopDropdown = useCallback(() => {
    setShopDropdown(false);
  }, [shopDropdown]);

  const toggleCart = useCallback(() => {
    setIsCartOpen(!isCartOpen);
  }, [isCartOpen]);

  const toggleFullscreen = useCallback(() => {
    setIsExpanded(!isExpanded);
    hideSearch();
  }, [isExpanded]);

  if (!currentPage) return null;
  if (currentPage.title === 'Support' || !currentPage.title) return null;
  return (
    <div>
      <HeaderFullscreen
        pages={pages}
        currentPage={currentPage}
        isOpen={isExpanded}
        shopSubNav={shopSubNav}
        shopNavHelper={shopNavHelper}
        onClose={onCollapse}
      />
      <div className="header js-header">
        <div className={classNames('header__overlay fix top left fill')} />
        <div
          id="header__site"
          className={classNames('header__site fix top left x z5 bcw')}
        >
          {isStore && shop && shop.bannerText && shop.bannerText.length > 0 && (
            <div className="header__promo bcb cw ac">
              <p className="small m0 p05 caps">{shop.bannerText}</p>
            </div>
          )}
          <div
            className={classNames(
              'header__fill header_element abs top left x y bcy',
              {
                op1: isExpanded,
                op0: !isExpanded
              }
            )}
          />
          <div className="container--xl rel ma f fr aic jcc header__site-container">
            {/* Mobile */}
            <div className="hide--m x header__bar">
              <div className="x y f aic px15">
                <div className="x f fr aic">
                  <div className="header__mobile__bar_buttons_left">
                    <div
                      role="button"
                      aria-label="search"
                      className="clickable"
                      onClick={showSearch}
                    >
                      <SearchIcon />
                    </div>
                  </div>
                  <Link to={'/'} className="f1 f aic jcc">
                    <LogoLong className="header__site__logo" />
                  </Link>

                  <div
                    className="header__mobile__bar_buttons_right jce"
                    style={{
                      width: cartQuantity > 0 ? '115px' : '45px'
                    }}
                  >
                    {cartQuantity > 0 && (
                      <button className="f aic jcc clickable" onClick={toggleCart}>
                        <CartIcon />
                        <div className="header__site-count ml05 f ac aic jcc bcb">
                          <span className="cw xsmall b akkura">{cartQuantity}</span>
                        </div>
                      </button>
                    )}
                    <div
                      role="button"
                      aria-label="Navigation"
                      className="header__site__toggle_container clickable"
                      onClick={toggleFullscreen}
                    >
                      <div
                        className={`header__site__toggle f jcc aic ${isExpanded &&
                          'open'}`}
                      >
                        <span className="header__site__toggle__lines cb bcb x" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Cart isOpen={isCartOpen} />
            </div>

            {/* Desktop */}
            <div className="show--m x header__bar">
              <div className="x y f aic px15">
                <div className="x f fr aic jcc f1">
                  <Link to={'/'}>
                    <LogoLong className="header__site__logo mr2 p0" />
                  </Link>
                  <nav
                    className="f1 f fr"
                    role="navigation"
                    aria-label="Global"
                    aria-hidden={isExpanded}
                  >
                    <ul
                      className={classNames('f1 f fr jcsa aic', {
                        header__enabled: !isExpanded,
                        header__disabled: isExpanded
                      })}
                    >
                      {pages.map((page, pageIndex) => {
                        if (page.title === 'Shop') {
                          return (
                            <HeaderSubnav
                              fullscreen={false}
                              currentPage={currentPage}
                              page={page}
                              pageIndex={pageIndex}
                              isExpanded={isExpanded}
                              isOpen={null}
                              shopDropdown={shopDropdown}
                              shopCategories={shopCategories}
                              shopSubNav={shopSubNav}
                              onClose={null}
                              shopNavHelper={shopNavHelper}
                              showShopDropdown={showShopDropdown}
                              closeShopDropdown={closeShopDropdown}
                            />
                          );
                        } else
                          return (
                            <li
                              key={page.title}
                              className={classNames('caps s0 header__site-nav_link')}
                              style={{
                                borderBottom:
                                  currentPage?.path === page.path
                                    ? '2px solid black'
                                    : ''
                              }}
                            >
                              {page.title === 'Space Agency' ? (
                                <a
                                  className={classNames('', {
                                    active: currentPage?.path === page.path
                                  })}
                                  href={page.path}
                                  aria-hidden={isExpanded}
                                  onClick={() => shopNavHelper(false)}
                                >
                                  {page.title}
                                </a>
                              ) : (
                                <Link
                                  className={classNames('', {
                                    active: currentPage?.path === page.path
                                  })}
                                  to={page.path}
                                  aria-hidden={isExpanded}
                                  onClick={() => shopNavHelper(false)}
                                >
                                  {page.title}
                                </Link>
                              )}
                            </li>
                          );
                      })}
                    </ul>
                  </nav>

                  <div
                    className={classNames('f fr rel aic ml1', {
                      header__enabled: !isExpanded,
                      header__disabled: isExpanded
                    })}
                  >
                    <a
                      href="https://twitter.com/SuperclusterHQ"
                      target="blank"
                      className="ml075 clickable"
                    >
                      <TwitterIcon />
                    </a>
                    <a
                      href="https://www.instagram.com/superclusterhq/"
                      target="blank"
                      className="ml075 clickable"
                    >
                      <IGIcon />
                    </a>
                    <div className="width-1 bcdg header__site-divider ml1" />
                    <button
                      className="f aic jcc px075 clickable"
                      onClick={() => setIsCartOpen((c) => !c)}
                    >
                      <CartIcon />
                      {cartQuantity > 0 && (
                        <div className="header__site-count ml05 f ac aic jcc bcb">
                          <span className="cw xsmall b akkura">{cartQuantity}</span>
                        </div>
                      )}
                    </button>
                    <div className="width-1 bcdg header__site-divider" />
                  </div>

                  <div
                    role="button"
                    className="clickable mx075"
                    onClick={showSearch}
                  >
                    <SearchIcon />
                  </div>
                  <div className="width-1 bcdg header__site-divider mr1" />

                  <div
                    role="button"
                    className="header__site__toggle_container clickable"
                    onClick={toggleFullscreen}
                  >
                    <div
                      className={`header__site__toggle f jcc aic ${isExpanded &&
                        'open'}`}
                    >
                      <span className="header__site__toggle__lines cb bcb x" />
                    </div>
                  </div>

                  <Cart isOpen={isCartOpen} />
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames('header__search x ovfh', {
              header__search_open: isSearching
            })}
          >
            <Search isOpen={isSearching} onClose={() => setIsSearching(false)} />
          </div>

          {showSubheader && (
            <div className="bcy">
              <div className={classNames('header__launch')}>
                <HeaderLaunchBanner launch={nextLaunch} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Header);
