import React, { useRef, useState, useEffect, FC } from 'react';
import cx from 'classnames';
import { deriveLaunchState, Launch } from 'superclient';
import { useWindowSize } from '../../state/use-window-size';
import ImageBackgroundSanity from '../core/image-background-sanity';
import SuperCard, { ListTypes, SuperCardProps } from '../core/super-card';

const LaunchResult: FC<{
  launch?: Launch;
  white?: boolean;
} & SuperCardProps> = ({
  launch,
  listType = ListTypes.CardGrid,
  white = false,
  onClick = () => {}
}) => {
  const windowSize = useWindowSize();
  const bioRef = useRef<HTMLDivElement>(null);
  const heightRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState(1.4);
  const [lineHeight, setLineHeight] = useState(1.7);
  const [descriptionPadding, setDescriptionPadding] = useState(0);

  useEffect(() => {
    const titleHeight = heightRef.current ? heightRef.current.offsetHeight : 100;
    const bioWidth = bioRef.current ? bioRef.current.offsetWidth : 150;
    const headline = launch && launch.headline ? launch.headline : '';
    if (headline.length < 15) {
      setFontSize(bioWidth * 0.009);
      setLineHeight(bioWidth * 0.011);
      setDescriptionPadding(titleHeight * 0.75);
    } else if (headline.length >= 15 && headline.length < 20) {
      setFontSize(bioWidth * 0.008);
      setLineHeight(bioWidth * 0.01);
      setDescriptionPadding(titleHeight * 0.5);
    } else {
      setFontSize(bioWidth * 0.007);
      setLineHeight(bioWidth * 0.009);
      setDescriptionPadding(titleHeight * 0.25);
    }
  }, [bioRef.current, windowSize, launch]);

  const { slug } = launch;
  const { launchDate } = launch.launchInfo;

  const { shortDescription, launchState, isLaunching } = deriveLaunchState(
    launchDate.utc
  );

  const rockets = (launch.launchModules || [])
    .filter((m) => m.module._type === 'rocket')
    .map((m) => m.module.title);

  return (
    <SuperCard
      key={launch._id}
      linkTo={`/launches/${slug.current}`}
      onClick={onClick}
      listType={listType}
      className={cx('fr', { bcg: !white, bcw: white })}
    >
      <div className="astronaut_card__image rel bcdg f aic jcc">
        <div className="abs x y top left bcb p1 f aic jcc">
          <ImageBackgroundSanity
            image={launch.image}
            alt={launch.headline}
            width={300}
            lazyLoad={listType === ListTypes.CardGrid}
          />
        </div>
      </div>
      <div className="astronaut_card__bio">
        <div className="astronaut_card__bio_text f fc">
          <div className="f fc fa">
            <div
              className={cx('rel f fc px075 py075', {
                bcy: isLaunching
              })}
            >
              <div
                className={cx('f1 bau astronaut_card__title', {
                  cw: isLaunching
                })}
                style={{
                  margin: 0,
                  fontSize: `${fontSize}rem`,
                  lineHeight: `${lineHeight}rem`
                }}
              >
                {launch.headline}
              </div>
              <div
                className={cx('mt1 x xsmall akkura caps', {
                  cw: isLaunching
                })}
              >
                {launchState}
              </div>
            </div>

            <div className={'f fr px075 ' + `py0${descriptionPadding}`}>
              <div className="caps xsmall launch__card-timing akkura bcb cw py05 px075 mr05">
                {shortDescription}
              </div>
            </div>

            <div className="f fc f1 p075 pr3 akkura xsmall caps">
              <div className="f1 px075" />

              {rockets.length > 0 && (
                <div className="px075">
                  <div className="x pb05">
                    <u>ROCKET</u>
                  </div>
                  {rockets[0]}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </SuperCard>
  );
};

export default React.memo(LaunchResult);
