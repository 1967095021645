import React, { FC } from 'react';
import { DateTime } from 'luxon';
import { SearchResult } from 'superclient';
import ImageBackground from '../core/image-background';
import SuperCard, { ListTypes } from '../core/super-card';

const SearchResultArticle: FC<{
  result: SearchResult;
  listType?: ListTypes;
  onClick: () => void;
}> = ({ result: { _source: article }, listType = ListTypes.CardGrid, onClick }) => {
  return (
    <SuperCard
      key={article._id}
      linkTo={`/editorial/${article.slug}`}
      onClick={onClick}
      listType={listType}
      className="fc"
    >
      <div className="rel article__search__card__image">
        <ImageBackground
          source={article.contentfulImageUrl}
          alt={article.title}
          lazyLoad={listType === ListTypes.CardGrid}
        />
      </div>
      <div className="h5 py1">{article.title}</div>
      <div className="x height-1 bcb" />
      <div className="f fr aic">
        <div className="caps small article__card__date bau pt1 mr05">
          {DateTime.fromISO(article.articleDate).toFormat('DDD')}
        </div>
        <div className="f1" />
        <div className="xsmall caps bau pt1">Article</div>
      </div>
    </SuperCard>
  );
};

export default SearchResultArticle;
