import React from 'react';

export default function SVG({ className = '' }) {
  return (
    <svg
      className={className}
      width="14px"
      height="13px"
      viewBox="0 0 14 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="sc-header_2020-06-10_A"
          transform="translate(-1040.000000, -56.000000)"
          stroke="#000000"
          strokeWidth="0.746"
        >
          <path
            d="M1049.26745,61.1331739 C1049.26745,63.4166283 1047.41663,65.2674515 1045.13428,65.2674515 C1042.85082,65.2674515 1041,63.4166283 1041,61.1331739 C1041,58.8497195 1042.85082,57 1045.13428,57 C1047.41663,57 1049.26745,58.8497195 1049.26745,61.1331739 Z M1053,69 L1048.05675,64.0556424 L1053,69 Z"
            id="search-icon"
          ></path>
        </g>
      </g>
    </svg>
  );
}
