import React, { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import { getImageUrl, SanityImage } from 'superclient';
import { Superclient } from '../../clients/superclient';

const ImageSanity: FC<{
  image?: SanityImage;
  alt?: string;
  className?: string;
  width?: number;
  height?: number;
  ratioMultipler?: number;
}> = ({ image, alt, className, width, height, ratioMultipler }) => {
  const [loaded, setLoaded] = useState(false);

  const src = useMemo(() => {
    if (!image) return undefined;
    const sanityUrl = image.asset.url;
    if (sanityUrl.endsWith('.svg') || sanityUrl.endsWith('.SVG')) {
      return sanityUrl;
    }
    return Superclient.getImageUrl(image, width, height, 3 * (ratioMultipler || 1));
  }, []);

  return (
    <div className={classNames('fill image__block', className)}>
      <img
        alt={alt}
        src={src}
        className={classNames('x is-visible', {
          'is-loaded': loaded
        })}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

export default React.memo(ImageSanity);
