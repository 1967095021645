import React, { FC, useCallback, useRef, useState } from 'react';
import astrochimp from 'astrochimp';

const mailChimpUrl =
  '//supercluster.us19.list-manage.com/subscribe/post?u=83b040af7bf23cbfec2452c69&amp;id=40d6e4659e';

const FooterNewsletterSignup: FC = () => {
  const [error, setError] = useState<string>();
  const [submitted, setSubmitted] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();
    astrochimp(mailChimpUrl, { EMAIL: formRef.current.email.value }, (err) => {
      if (err) {
        console.log(err);
        setError(err.msg);
      } else {
        setSubmitted(true);
      }
    });
  }, []);

  return (
    <div className="pb15 pr1 pb1 container--xl x footer__newsletter mb15">
      {!submitted ? (
        <div className="">
          <h5 className="mb1 x">Want more space stories?</h5>
          {!error ? (
            <form
              className="f x jcb aic rel"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <input
                className="small"
                name="email"
                type="email"
                required
                placeholder="Enter Email"
              />
              <button type="submit" className="small caps btn">
                Submit
              </button>
            </form>
          ) : (
            <div className="small x">{error}</div>
          )}
        </div>
      ) : (
        <div className="my05">
          <h5 className="small akkura caps">
            We got you. More Supercluster headed your way.
          </h5>
        </div>
      )}
    </div>
  );
};

export default FooterNewsletterSignup;
