import React, { FC, useCallback, useRef, useState } from 'react';
import astrochimp from 'astrochimp';
import classNames from 'classnames';

const mailChimpUrl =
  '//supercluster.us19.list-manage.com/subscribe/post?u=83b040af7bf23cbfec2452c69&amp;id=40d6e4659e';

const EditorialNewsletterModule: FC<{
  data: any;
  backgroundColor?: string;
  darkBackground?: boolean;
  padding?: boolean;
}> = ({ data, backgroundColor = 'white', darkBackground, padding = true }) => {
  const [error, setError] = useState<string>();
  const [submitted, setSubmitted] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();
    astrochimp(mailChimpUrl, { EMAIL: formRef.current.email.value }, (err) => {
      if (err) {
        console.log(err);
        setError(err.msg);
      } else {
        setSubmitted(true);
      }
    });
  }, []);

  const { headline, tagline, cta, successMessage, placeholder } = data;

  return (
    <div className={`z2 container--xl ${padding ? 'ma p1' : ''}`}>
      <div className="rel newsletter_module" data-dark-background={darkBackground}>
        {!submitted ? (
          <div className="">
            <div className="newsletter_module__text f fc mb1 aic">
              <div className="newsletter_module__headline h3">{headline}</div>
              <div className="newsletter_module__tagline f1 h5">{tagline}</div>
            </div>
            {!error ? (
              <form
                className="f x jcb aic rel"
                ref={formRef}
                onSubmit={handleSubmit}
              >
                <input
                  className="small"
                  name="email"
                  type="email"
                  required
                  placeholder={placeholder}
                />
                <button
                  type="submit"
                  className="small caps btn"
                  data-dark-background={darkBackground}
                >
                  <span style={{ color: backgroundColor }}>{cta}</span>
                </button>
              </form>
            ) : (
              <div
                className="small x"
                dangerouslySetInnerHTML={{ __html: error }}
              />
            )}
          </div>
        ) : (
          <div className="my05">
            <h5 className="small akkura caps">{successMessage}</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(EditorialNewsletterModule);
