import React from 'react';

export default function SVG({ className = '' }) {
  return (
    <svg
      className={className}
      width="14px"
      height="13px"
      viewBox="0 0 14 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="sc-header_2020-06-10_A"
          transform="translate(-1003.000000, -56.000000)"
          fill="#000000"
        >
          <g id="rss-icon" transform="translate(1003.000000, 56.000000)">
            <path
              d="M3.517,6.99875988 C3.517,6.48287087 3.169,6.25313905 2.961,6.16144784 L2.685,6.04053635 L2.959,5.9115641 C3.306,5.74833359 3.482,5.4178422 3.482,4.93016587 L3.482,1.09324136 C3.482,0.387924353 3.153,0 2.556,0 L0,0 L0,13 L1.463,13 L1.463,6.89800031 L1.723,6.89800031 C2.054,6.89800031 2.054,7.15594481 2.054,7.26678034 L2.054,12.7088048 C2.054,12.8428151 2.072,12.9385367 2.108,13 L3.547,13 C3.526,12.9062936 3.517,12.7984809 3.517,12.6554023 L3.517,6.99875988 Z M2.054,5.30499147 C2.054,5.57704232 1.883,5.67377151 1.723,5.67377151 L1.463,5.67377151 L1.463,1.06301349 L1.741,1.06301349 C2.054,1.06301349 2.054,1.31188963 2.054,1.43280112 L2.054,5.30499147 Z"
              id="Fill-55"
            ></path>
            <path
              d="M6.822,5.16047968 C6.588,4.91025053 6.516,4.77421326 6.516,4.39787657 L6.516,1.41300031 C6.516,1.10021387 6.736,1.0664528 6.83,1.0664528 C6.924,1.0664528 7.143,1.10021387 7.143,1.41300031 L7.143,4.73548732 L8.517,4.73548732 L8.517,1.34150626 C8.517,0.426978307 8.043,0 7.025,0 L6.635,0 C5.556,0 5.054,0.471662084 5.054,1.4825084 L5.054,4.46738466 C5.054,5.26672777 5.261,5.7771158 5.791,6.28651085 L6.731,7.20203178 C7.027,7.4969447 7.072,7.65284143 7.072,8.03712191 L7.072,11.431103 C7.072,11.8183624 6.819,11.8481515 6.741,11.8481515 C6.624,11.8481515 6.427,11.793538 6.427,11.431103 L6.427,7.6101436 L5,7.6101436 L5,11.5532386 C5,12.5273449 5.512,13 6.564,13 L6.954,13 C8.038,13 8.588,12.4419493 8.588,11.3427284 L8.588,7.96662084 C8.588,7.11564314 8.399,6.69164375 7.746,6.07897953 L6.822,5.16047968 Z"
              id="Fill-57"
            ></path>
            <path
              d="M11.822,5.16047968 C11.588,4.91025053 11.516,4.77322029 11.516,4.39787657 L11.516,1.41300031 C11.516,1.10021387 11.736,1.0664528 11.83,1.0664528 C11.924,1.0664528 12.143,1.10021387 12.143,1.41300031 L12.143,4.73548732 L13.518,4.73548732 L13.518,1.34150626 C13.518,0.426978307 13.043,0 12.025,0 L11.635,0 C10.556,0 10.054,0.471662084 10.054,1.4825084 L10.054,4.46738466 C10.054,5.26672777 10.261,5.77612282 10.792,6.28651085 L11.731,7.20203178 C12.028,7.4969447 12.073,7.65184846 12.073,8.03712191 L12.073,11.431103 C12.073,11.8183624 11.819,11.8481515 11.741,11.8481515 C11.624,11.8481515 11.427,11.793538 11.427,11.431103 L11.427,7.6101436 L10,7.6101436 L10,11.5532386 C10,12.5273449 10.512,13 11.564,13 L11.954,13 C13.038,13 13.588,12.4419493 13.588,11.3427284 L13.588,7.96662084 C13.588,7.11564314 13.399,6.69164375 12.746,6.07897953 L11.822,5.16047968 Z"
              id="Fill-59"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
