import React, { FC, createContext, useState, useContext } from 'react';
import { ShopifyProductType } from 'superclient';

export interface ShopContextValue {
  productTypeFilter?: ShopifyProductType;
  setProductTypeFilter: (type?: ShopifyProductType) => void;
}

const initialState: ShopContextValue = {
  productTypeFilter: undefined,
  setProductTypeFilter: () => {}
};

const ShopContext = createContext<ShopContextValue>(initialState);
const useShopContext = (): ShopContextValue => useContext(ShopContext);
const { Provider, Consumer: ShopContextConsumer } = ShopContext;

const ShopContextProvider: FC<{ children: any }> = ({ children }) => {
  const [productTypeFilter, setProductTypeFilter] = useState<ShopifyProductType>();

  const value: ShopContextValue = {
    productTypeFilter,
    setProductTypeFilter
  };

  return <Provider value={value}>{children}</Provider>;
};

export { ShopContext, useShopContext, ShopContextConsumer, ShopContextProvider };
