import React from 'react';

export default function SVG({ className = '' }) {
  return (
    <svg
      className={className}
      width="13px"
      height="13px"
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="sc-header_2020-06-10_A"
          transform="translate(0.5, 0.5), scale(0.0103)"
          fill="#000000"
          fillRule="nonzero"
        >
          <path
            d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
            fill="black"
            id="twitter-icon"
          ></path>
        </g>
      </g>
    </svg>
  );
}
