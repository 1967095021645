import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import cookie from 'js-cookie';
import ShopifyBuy from 'shopify-buy';
import { shopifyClient } from '../clients/shopify';

export type UnifiedLineItem = ShopifyBuy.LineItem & ShopifyBuy.Item;

interface ShopifyStateContextValue {
  checkout?: ShopifyBuy.Cart;
  lineItems: UnifiedLineItem[];
  cartQuantity: number;
  addItem: (variantId: string, quantity: number) => Promise<void>;
  updateItem: (lineItemId: string, quantity: number) => Promise<void>;
  removeItem: (lineItemId: string) => Promise<void>;
}

const initialState: ShopifyStateContextValue = {
  checkout: undefined,
  lineItems: [],
  cartQuantity: 0,
  addItem: async () => {},
  updateItem: async () => {},
  removeItem: async () => {}
};

const ShopifyStateContext = createContext<ShopifyStateContextValue>(initialState);
const useShopifyState = (): ShopifyStateContextValue =>
  useContext(ShopifyStateContext);
const { Provider, Consumer: ShopifyStateConsumer } = ShopifyStateContext;

const ShopifyStateProvider: FC = ({ children }) => {
  const [checkout, setCheckout] = useState<ShopifyBuy.Cart>();

  const createCheckout = () => {
    shopifyClient.checkout.create().then((checkout) => {
      const checkoutId = checkout.id;
      cookie.set('sc_checkout', checkoutId, { expires: 25 });
      setCheckout(checkout);
    });
  };

  useEffect(() => {
    const checkoutId = cookie.get('sc_checkout');
    if (checkoutId) {
      shopifyClient.checkout.fetch(checkoutId).then((checkout) => {
        if (!checkout || checkout.completedAt) {
          createCheckout();
        } else {
          setCheckout(checkout);
        }
      });
    } else {
      return createCheckout();
    }
  }, []);

  const addItem = useCallback(
    (variantId: string, quantity: number) => {
      return shopifyClient.checkout
        .addLineItems(checkout.id, [{ variantId, quantity }])
        .then(setCheckout);
    },
    [shopifyClient, checkout]
  );

  const updateItem = useCallback(
    (lineItemId: string, quantity) => {
      return shopifyClient.checkout
        .updateLineItems(checkout.id, [{ id: lineItemId, quantity }])
        .then(setCheckout);
    },
    [shopifyClient, checkout]
  );

  const removeItem = useCallback(
    (itemId: string) => {
      return shopifyClient.checkout
        .removeLineItems(checkout.id, [itemId])
        .then(setCheckout);
    },
    [shopifyClient, checkout]
  );

  const lineItems = useMemo(() => {
    if (!checkout) return [];
    return (checkout.lineItems as unknown) as UnifiedLineItem[];
  }, [checkout]);

  const cartQuantity = useMemo(() => {
    let quantity = 0;
    if (!checkout) return quantity;
    checkout.lineItems.forEach((item, i) => {
      quantity += item.quantity;
    });
    return quantity;
  }, [checkout]);

  const value = {
    checkout,
    lineItems,
    cartQuantity,
    addItem,
    updateItem,
    removeItem
  };

  return <Provider value={value}>{children}</Provider>;
};

export {
  ShopifyStateProvider,
  useShopifyState,
  ShopifyStateContext,
  ShopifyStateConsumer
};
