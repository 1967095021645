import React, { useState, useEffect, FC } from 'react';
import Cookies from 'js-cookie';

export const Doormat: FC = () => {
  const [doormatShown, setDoormatShown] = useState(true);

  useEffect(() => {
    setDoormatShown(Cookies.get('doormat_shown') === 'true');
  }, [0]);

  const dismiss = () => {
    Cookies.set('doormat_shown', true, { expires: 365 * 10 });
    setDoormatShown(true);
  };

  return (
    <div
      className="doormat__overlay fix fill z10 f aic jcc"
      style={{
        visibility: doormatShown ? 'hidden' : 'visible',
        opacity: doormatShown ? '0' : '1',
        transition: 'opacity 0.5s, visibility 0.5s'
      }}
      onClick={() => dismiss()}
    >
      <div
        className="doormat__container f rel no-scrollbar"
        style={{
          transform: doormatShown ? 'translateY(200vh)' : 'translateY(0)',
          transition: 'transform 0.5s linear'
        }}
      >
        <div className="doormat__image" />
        <div className="doormat__body p15">
          <div className="doormat">
            <h3 className="mt0">The Supercluster App</h3>
            <h4>
              Featuring our global Launch Tracker, interactive Astronaut Database,
              and Stations Dashboard. <br /> <br /> Stay on top of everything leaving
              Earth; get mission info, countdowns, livestreams, and customized
              notifications that alert you when a rocket is launching or when a space
              station is in the skies overhead.
            </h4>
            <div className="doormat__icon" />
          </div>
          <div className="f aic">
            <h4 className="caps">Download</h4>
            <span className="arrow__right arrow ml075 block cb rel" />
            <div className="doormat__links f fa ml1">
              <a
                className="doormat__link mx1 ac small bold akkura caps"
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/supercluster/id1500045135?ls=1"
              >
                iOS
              </a>
              <a
                className="doormat__link mx1 ac small bold akkura caps"
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.supercluster.tracker"
              >
                Android
              </a>
            </div>
          </div>
        </div>
        <div
          className="doormat__close abs top right mr1 mt1"
          onClick={() => dismiss()}
        >
          <span> </span>
        </div>
      </div>
    </div>
  );
};

export default Doormat;
