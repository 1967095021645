import React from 'react';

export default function SVG({ className = '' }) {
  return (
    <svg
      width="16px"
      height="12px"
      viewBox="0 0 16 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="supercluster_website_header_2020-06-10"
          transform="translate(-933.000000, -21.000000)"
          fill="#000000"
        >
          <g id="cart-icon" transform="translate(933.000000, 21.000000)">
            <g id="Group">
              <path
                d="M2.4,9.6 C1.73735979,9.6 1.2,10.1373598 1.2,10.8 C1.2,11.4626402 1.73735979,12 2.4,12 C3.06264021,12 3.6,11.4626402 3.6,10.8 C3.6,10.1373598 3.06264021,9.6 2.4,9.6"
                id="Fill-102"
              ></path>
              <path
                d="M9.6,9.6 C8.93735979,9.6 8.4,10.1373598 8.4,10.8 C8.4,11.4626402 8.93735979,12 9.6,12 C10.2626402,12 10.8,11.4626402 10.8,10.8 C10.8,10.1373598 10.2626402,9.6 9.6,9.6"
                id="Fill-104"
              ></path>
              <polygon
                id="Fill-107"
                points="15.6 0 0 0 2.4 8.4 9.6 8.4 11.138252 2.81050808 14.7534976 2.81050808"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
