import React, { FC, useState, useRef, useEffect } from 'react';
import { LazyImage } from 'react-lazy-images';
import cx from 'classnames';
import { useWindowSize } from '../../state/use-window-size';
import {
  abbreviateDuration,
  Astronaut,
  deriveAstronautIsAnimal,
  deriveAstronautMillisInSpace,
  deriveAstronautMillisSpacewalking
} from 'superclient';
import SuperCard, { ListTypes, SuperCardProps } from '../core/super-card';
import ImageBackgroundSanity from '../core/image-background-sanity';
import ImageSanity from '../core/image-sanity';
import MouseOver from '../core/mouse-over';

const AstronautCard: FC<{
  astronaut?: Astronaut;
  paramString?: string;
} & SuperCardProps> = ({
  astronaut,
  paramString = '',
  style,
  listType = ListTypes.CardGrid,
  onClick
}) => {
  const [mouseOverAward, setMouseOverAward] = useState(-1);
  const [mouseOverNation, setMouseOverNation] = useState(-1);
  const [mouseOverAgency, setMouseOverAgency] = useState(-1);
  const windowSize = useWindowSize();

  const bioRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState(1.4);
  const [lineHeight, setLineHeight] = useState(1.7);

  useEffect(() => {
    const bioWidth = bioRef.current ? bioRef.current.offsetWidth : 150;
    if (astronaut && astronaut.name.length < 15) {
      setFontSize(bioWidth * 0.007);
      setLineHeight(bioWidth * 0.0085);
    } else if (
      astronaut &&
      astronaut.name.length >= 15 &&
      astronaut.name.length < 20
    ) {
      setFontSize(bioWidth * 0.006);
      setLineHeight(bioWidth * 0.0075);
    } else {
      setFontSize(bioWidth * 0.005);
      setLineHeight(bioWidth * 0.007);
    }
  }, [bioRef.current, windowSize]);

  if (!astronaut) {
    return (
      <SuperCard listType={listType} className="fr bcg" style={style}>
        <div className="astronaut_card__image rel bcdg">
          <LazyImage
            src="/tbd-astronaut.jpg"
            alt="TBD Astronaut"
            placeholder={({ ref }) => (
              <div ref={ref} className="sanity_background_image__loader bcg" />
            )}
            actual={({ imageProps }) => (
              <div
                className={`abs fill fadeIn `}
                style={{
                  backgroundImage: `url(${imageProps.src})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              />
            )}
          />
        </div>
        <div className="astronaut_card__bio" ref={bioRef}>
          <div className="astronaut_card__bio_text f fc">
            <div className="f fc fa">
              <div className={'astronaut_card__head rel f fc px075 py075'}>
                <div className={'f fr mb025 x akkura small cbl'}>-</div>
                <div className="f1 ">
                  <div
                    className={'bau mr1 astronaut_card__title'}
                    style={{
                      fontSize: `${fontSize}rem`,
                      lineHeight: `${lineHeight}rem`
                    }}
                  >
                    TBD
                  </div>
                </div>
              </div>
              <div className="f3 mx1 my05 f fc jcc">
                <div className="f fr">
                  <div className="f1 xsmall akkura">-</div>
                  <div className="f3 xsmall akkura">MISSIONS</div>
                </div>
                <div className="f fr mt025">
                  <div className="f1 xsmall akkura">-</div>
                  <div className="f3 xsmall akkura">DAYS IN SPACE</div>
                </div>
                <div className="f fr mt025">
                  <div className="f1 xsmall akkura">-</div>
                  <div className="f3 xsmall akkura">SPACEWALKS</div>
                </div>
                <div className="f fr mt025">
                  <div className="f1 xsmall akkura">-</div>
                  <div className="f3 xsmall akkura">DAYS SPACEWALKING</div>
                </div>
              </div>

              <div className="f fr mx1 mb1 mt05">
                <div
                  style={{
                    width: '20px',
                    height: '20px'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </SuperCard>
    );
  }

  const isAnimal = deriveAstronautIsAnimal(astronaut);
  const totalSpaceDuration = deriveAstronautMillisInSpace(astronaut);
  const totalSpacewalkDuration = deriveAstronautMillisSpacewalking(astronaut);

  return (
    <SuperCard
      key={astronaut._id}
      linkTo={`/astronauts/${astronaut.slug.current}${paramString}`}
      onClick={onClick}
      listType={listType}
      className="fr bcg"
      style={style}
    >
      <div className="astronaut_card__image rel bcdg">
        {astronaut.image && astronaut.image.asset && (
          <ImageBackgroundSanity
            className="abs x y top left"
            image={astronaut.image}
            alt={astronaut.name}
            width={260}
            height={405}
            lazyLoad={listType === ListTypes.CardGrid}
          />
        )}
      </div>
      <div className="astronaut_card__bio" ref={bioRef}>
        <div className="astronaut_card__bio_text f fc">
          <div className="f fc fa">
            <div
              className={cx('astronaut_card__head rel f fc px075 py075', {
                bcbl: astronaut.inSpace
              })}
            >
              <div
                className={
                  'f fr mb025 x akkura small' + (astronaut.inSpace ? ' cw' : ' cbl')
                }
              >
                {astronaut.astroNumber || '-'}
                {astronaut.inSpace && (
                  <div className="ml1 akkura small cw caps rel">
                    <img
                      src="/orbit-white.gif"
                      className="abs top bottom"
                      style={{
                        width: '29px',
                        height: '29px',
                        top: '-6px'
                      }}
                    />
                    In Space
                  </div>
                )}
              </div>
              <div className="f1 ">
                <div
                  className={cx('bau mr1 astronaut_card__title', {
                    cw: astronaut.inSpace
                  })}
                  style={{
                    fontSize: `${fontSize}rem`,
                    lineHeight: `${lineHeight}rem`
                  }}
                >
                  {astronaut.name}
                </div>
              </div>
              <div className="f fc pt1 pr1 abs right top">
                {astronaut.nations &&
                  astronaut.nations.map((nation, i) => {
                    return nation.flag && nation.flag.asset ? (
                      <div
                        key={nation._id}
                        className="rel mb05 f fr jcc"
                        style={{
                          width: 30,
                          height:
                            30 / nation.flag.asset.metadata.dimensions.aspectRatio
                        }}
                        onMouseEnter={() => setMouseOverNation(i)}
                        onMouseLeave={() => setMouseOverNation(-1)}
                      >
                        <ImageSanity
                          className="abs x y top left"
                          image={nation.flag}
                          alt={nation.name}
                          width={60}
                        />
                        <MouseOver
                          textColor="white"
                          backgroundColor="#241F21"
                          text={nation.name}
                          className={cx('abs mt1', {
                            hide: mouseOverNation !== i
                          })}
                          style={{
                            marginTop:
                              30 / nation.flag.asset.metadata.dimensions.aspectRatio
                          }}
                        />
                      </div>
                    ) : (
                      <div />
                    );
                  })}
                {astronaut.agencies &&
                  astronaut.agencies.map((agency, i) => {
                    return agency.icon && agency.icon.asset ? (
                      <div
                        key={agency._id}
                        className="rel mb05 f fr jcc"
                        style={{
                          width: 30,
                          height:
                            30 / agency.icon.asset.metadata.dimensions.aspectRatio
                        }}
                        onMouseEnter={() => setMouseOverAgency(i)}
                        onMouseLeave={() => setMouseOverAgency(-1)}
                      >
                        <ImageSanity
                          className="abs x y top left"
                          image={agency.icon}
                          alt={agency.name}
                          width={60}
                        />
                        <MouseOver
                          text={agency.name}
                          textColor="white"
                          backgroundColor="#241F21"
                          className={cx('abs', {
                            hide: mouseOverAgency !== i
                          })}
                          style={{
                            marginTop:
                              30 / agency.icon.asset.metadata.dimensions.aspectRatio
                          }}
                        />
                      </div>
                    ) : (
                      <div key={agency._id} />
                    );
                  })}
              </div>
            </div>

            {isAnimal && (
              <div className="mx1 mb05 x xsmall akkura caps">
                {astronaut.species}
              </div>
            )}

            <div className="f3 mx1 my05 f fc jcc">
              <div className="f fr">
                <div className="f1 xsmall akkura">
                  {astronaut.missionCount > 0 ? astronaut.missionCount : '-'}
                </div>
                <div className="f3 xsmall akkura">MISSIONS</div>
              </div>
              {!isAnimal && (
                <>
                  <div className="f fr mt025">
                    <div className="f1 xsmall akkura">
                      {astronaut.totalMinutesInSpace !== undefined
                        ? abbreviateDuration(totalSpaceDuration)
                        : '-'}
                    </div>
                    <div className="f3 xsmall akkura">DAYS IN SPACE</div>
                  </div>
                  <div className="f fr mt025">
                    <div className="f1 xsmall akkura">
                      {astronaut.spacewalkCount > 0 ? astronaut.spacewalkCount : '-'}
                    </div>
                    <div className="f3 xsmall akkura">SPACEWALKS</div>
                  </div>
                  <div className="f fr mt025">
                    <div className="f1 xsmall akkura">
                      {astronaut.totalSecondsSpacewalking !== undefined
                        ? abbreviateDuration(totalSpacewalkDuration)
                        : '-'}
                    </div>
                    <div className="f3 xsmall akkura">DAYS SPACEWALKING</div>
                  </div>
                </>
              )}
            </div>

            <div className="f fr mx1 mb1 mt05">
              {(!astronaut.awards || astronaut.awards.length === 0) && (
                <div
                  style={{
                    width: '20px',
                    height: '20px'
                  }}
                />
              )}
              {astronaut.awards &&
                astronaut.awards.slice(0, 6).map((award, i) => {
                  if (!award.awardType) {
                    return <div key={i} />;
                  }

                  return (
                    <div
                      key={award._key}
                      className="rel"
                      onMouseEnter={() => setMouseOverAward(i)}
                      onMouseLeave={() => setMouseOverAward(-1)}
                    >
                      <div
                        className={cx('f fr jcc', {
                          ml05: i > 0
                        })}
                        style={{
                          width:
                            20 *
                            award.awardType.image.asset.metadata.dimensions
                              .aspectRatio,
                          height: 20
                        }}
                      >
                        <div
                          style={{
                            width:
                              20 *
                              award.awardType.image.asset.metadata.dimensions
                                .aspectRatio,
                            height: 20
                          }}
                        >
                          <ImageSanity
                            className={cx('x y top left', {
                              hide: mouseOverAward !== i
                            })}
                            image={award.awardType.blueImage}
                            alt={award.title}
                            height={30}
                          />
                          <ImageSanity
                            className={cx('x y top left', {
                              hide: mouseOverAward === i
                            })}
                            image={award.awardType.image}
                            alt={award.title}
                            height={30}
                          />
                        </div>
                        <MouseOver
                          text={award.title}
                          textColor="white"
                          backgroundColor="#241F21"
                          className={cx('abs mt1', {
                            hide: mouseOverAward !== i
                          })}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </SuperCard>
  );
};

export default React.memo(AstronautCard);
