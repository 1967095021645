import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import { Link } from 'gatsby';
import { DateTime } from 'luxon';
import FooterNewsletterSignup from './footer-newsletter';
import TwitterIcon from '../svgs/twitter-icon';
import { Fact } from 'superclient';
import { Superclient } from '../../clients/superclient';

const date = new Date();

const Footer: FC = () => {
  const [facts, setFacts] = useState<Fact[]>();

  useEffect(() => {
    Superclient.getContentfulFacts().then(setFacts);
  }, []);

  const fact = useMemo(() => {
    if (!facts) return;
    const s = DateTime.local();
    facts.forEach((fact) => {
      if (fact.date) {
        const testS = DateTime.fromISO(fact.date);
        if (testS.day === s.day && testS.month === s.month) {
          return fact.fact;
        }
      }
    });

    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    // @ts-ignore
    const diff = now - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);
    const index = day % facts.length;
    const randomFact = facts[index];
    return randomFact.fact;
  }, [facts]);

  return (
    <div className="footer">
      <div className="rel container--xl ma x">
        <div className="container--xl ma px15 pt2 f jcb ais">
          <div className="footer__logo f jcs iac">
            <svg viewBox="0 0 88.84 89.1">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path d="M88.84,35.26V88.93a26.83,26.83,0,1,1,0-53.67ZM30.89,37.65C22.14,51,16.19,60.11,0,62V89.1c31-2.34,43.47-21.35,53.49-36.66C62.56,38.57,69.29,28.28,88.84,27V0C54.57,1.48,41.49,21.45,30.89,37.65Z" />
                </g>
              </g>
            </svg>
            <span className="small ml15 pl05 mt1">Our Greatest Space Stories</span>
          </div>
          <ul className="f jce aic footer__links">
            <li className="caps s0 ml05">
              <Link to="/">Stories</Link>
            </li>
            <li className="caps s0 ml05">
              <Link to="/launches">Launches</Link>
            </li>
            <li className="caps s0 ml05">
              <Link to="/astronauts">Astronauts</Link>
            </li>
            <li className="caps s0 ml05">
              <Link to="/stations">Station Traffic</Link>
            </li>
            <li className="caps s0 ml05">
              <Link to="/store">Shop</Link>
            </li>
            <li className="caps s0 ml05">
              <Link to="/about">about</Link>
            </li>
          </ul>
        </div>
        <div className="footer__columns mx1">
          <div className="footer__column">
            <h6 className="caps mb0 pb0">Fact of the Day</h6>
            <ReactMarkdown
              className="container--s s1"
              plugins={[breaks]}
              source={fact}
            />

            <ul className="f jcs aic mt2 pb1 footer__social">
              <li className="mr2">
                <a
                  href="https://www.instagram.com/superclusterhq"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>
              </li>
              <li className="mr2">
                <a
                  href="https://twitter.com/SuperclusterHQ"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <TwitterIcon />
                </a>
              </li>
              <li className="mr2">
                <a
                  href="https://www.youtube.com/channel/UCBViltDKyQjzP2IDCFVkLeA"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                  </svg>
                </a>
              </li>
            </ul>
            <div className="newsletter__wrap">
              <FooterNewsletterSignup />
            </div>
          </div>
          <div className="footer__column">
            <h6 className="caps mb0 pb0">GET THE SUPERCLUSTER APP</h6>
            <div className="f fr ais jcs mt1">
              <a
                href="https://apps.apple.com/us/app/supercluster/id1500045135?ls=1"
                className="caps btn btn__white btn--outline small ac"
              >
                IOS
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.supercluster.tracker"
                className="caps btn btn__white ml1 btn--outline small ac"
              >
                ANDROID
              </a>
            </div>

            <div className="f1 pt2" />

            <h6 className="caps mb0 pb0">THE SUPERCLUSTER PODCAST</h6>
            <div className="f fr x aifs mt1">
              <a
                href="https://anchor.fm/supercluster"
                className="caps btn btn__white mr2 btn--outline small ac"
              >
                LISTEN
              </a>
              <p className="f1 small bau m0">
                A podcast exploring the amazing milestones that changed space
                history, the wildest ideas that drive our future, and every
                development in this new Golden Age of Space.
              </p>
            </div>
          </div>
          <div className="footer__column">
            <h6 className="caps mb0 pb0">Donate to support</h6>
            <div className="f fr x aifs mt1">
              <p className="f1 small bau m0 mr3 pr1">
                Your support makes the Astronaut Database and Launch Tracker
                possible, and keeps all Supercluster content free.
              </p>
              <Link
                to="/support"
                className="caps btn btn__white ml2 btn--outline small ac"
              >
                Support
              </Link>
            </div>

            <div className="f1 pt2" />

            <a
              href="https://www.dropbox.com/sh/vmcoq13743s8arn/AABxB9_GoJ7u56J4jMZHIIKXa?dl=0&preview=Supercluster+Privacy+Policy+-+Website+-+v1.pdf"
              className="m0 small akkura x ar"
            >
              READ OUR PRIVACY POLICY
            </a>
            <p className="m0 small akkura x ar">COPYRIGHT 2021 SUPERCLUSTER LLC</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
