import React, { FC, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { deriveLaunchState, Mission } from 'superclient';
import { useWindowSize } from '../../state/use-window-size';
import ImageBackgroundSanity from '../core/image-background-sanity';
import ImageSanity from '../core/image-sanity';
import SuperCard, { ListTypes, SuperCardProps } from '../core/super-card';

const MissionCard: FC<{
  mission: Mission;
  paramString?: string;
  white?: boolean;
} & SuperCardProps> = ({
  mission,
  style,
  className,
  paramString = '',
  white = false,
  listType,
  onClick
}) => {
  const windowSize = useWindowSize();

  const bioRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState(1.4);
  const [lineHeight, setLineHeight] = useState(1.7);

  useEffect(() => {
    const bioWidth = bioRef.current ? bioRef.current.offsetWidth : 150;
    if (mission.name.length < 15) {
      setFontSize(bioWidth * 0.007);
      setLineHeight(bioWidth * 0.0085);
    } else if (mission.name.length >= 15 && mission.name.length < 20) {
      setFontSize(bioWidth * 0.006);
      setLineHeight(bioWidth * 0.0075);
    } else {
      setFontSize(bioWidth * 0.005);
      setLineHeight(bioWidth * 0.007);
    }
  }, [bioRef.current, windowSize]);

  const launchDate = mission.launchDate
    ? mission.launchDate.utc
    : mission.vagueLaunchDate && mission.vagueLaunchDate.split('-').length === 3
    ? DateTime.fromObject({
        year: parseInt(mission.vagueLaunchDate.split('-')[0], 10),
        month: parseInt(mission.vagueLaunchDate.split('-')[1], 10),
        day: parseInt(mission.vagueLaunchDate.split('-')[2], 10)
      }).toISO()
    : undefined;

  const { shortDescription, launchState, isLaunching } = deriveLaunchState(
    launchDate
  );

  return (
    <SuperCard
      key={mission._id}
      linkTo={`/missions/${mission.slug.current}${paramString}`}
      onClick={onClick}
      listType={listType}
      style={style}
      className={classNames('fr ' + className, { bcg: !white, bcw: white })}
    >
      <div className="astronaut_card__image rel bcdg f aic jcc">
        <div className="abs x y top left bcb p1 f aic jcc">
          {mission.image && mission.image.asset && (
            <div
              style={{
                position: 'absolute',
                width: '75%',
                paddingBottom: '75%'
              }}
            >
              <ImageBackgroundSanity
                image={mission.image}
                alt={mission.name}
                width={300}
                lazyLoad={listType === ListTypes.CardGrid}
              />
            </div>
          )}
        </div>
      </div>
      <div className="astronaut_card__bio" ref={bioRef}>
        <div className="abs right bottom pr1 pb1">
          {mission.spacecrafts &&
            mission.spacecrafts.length > 0 &&
            mission.spacecrafts[0].filledVerticalIcon && (
              <div className="f aic">
                <div
                  className="rel"
                  style={{
                    width: 20,
                    height:
                      20 /
                      mission.spacecrafts[0].filledVerticalIcon.asset.metadata
                        .dimensions.aspectRatio
                  }}
                >
                  <ImageSanity
                    className="abs x y top left"
                    image={mission.spacecrafts[0].filledVerticalIcon}
                    alt={mission.spacecrafts[0].title}
                    width={50}
                  />
                </div>
              </div>
            )}
        </div>

        <div className="astronaut_card__bio_text f fc">
          <div className="f fc fa">
            <div
              className={classNames('rel f fc px075 py075', {
                bcy: isLaunching
              })}
            >
              <div
                className={classNames('f1 bau astronaut_card__title', {
                  cw: isLaunching,
                  mr0: true
                })}
                style={{
                  fontSize: `${fontSize}rem`,
                  lineHeight: `${lineHeight}rem`
                }}
              >
                {mission.name}
              </div>
              <div
                className={classNames('mt1 x xsmall akkura caps', {
                  cw: isLaunching
                })}
              >
                {launchState}
              </div>
            </div>

            <div className="f fr p075">
              <div className="caps xsmall launch__card-timing akkura bcb cw py05 px075 mr05">
                {shortDescription}
              </div>
            </div>

            <div className="f fc f1 p075 pr3 akkura xsmall caps">
              <div className="f1 px075" />

              <div className="px075">
                <div className="x pb05">
                  <u>ROCKET</u>
                </div>
                {mission.spacecrafts &&
                  mission.spacecrafts.length > 0 &&
                  mission.spacecrafts[0].title}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SuperCard>
  );
};

export default React.memo(MissionCard);
